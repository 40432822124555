import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { Card } from "react-bootstrap"
const spokenWord = () => {
  return (
    <Layout>
      <Helmet>
        <title>Spoken Word - Pamela Mary Brown</title>
      </Helmet>
      <h2>Spoken Word</h2>
      <Card style={{ border: "none", backgroundColor: "transparent" }}>
        <Card.Title style={{ marginTop: "10px" }}>
          Granny Poem - Pamela Brown
        </Card.Title>
        <div className="video-style">
          <iframe
            width="100%"
            height="350"
            src="https://www.youtube.com/embed/gREGz9Q5Br4"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <hr style={{ background: "black" }} />
        <Card.Title style={{ marginTop: "10px" }}>
          Frabulous - Pamela Brown
        </Card.Title>
        <div className="video-style">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/bMuUSInMc_w"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </Card>
    </Layout>
  )
}

export default spokenWord
